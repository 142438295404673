<template>
  <div class="apply2">
    <van-nav-bar
      title="填写入驻信息"
      :border="false"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar
      v-show="show"
      wrapable
      :scrollable="false"
      color="#ffffff"
      background="#EE0A24"
      left-icon="warning-o"
    >
      {{reason}}
    </van-notice-bar>
    <van-form @submit="onSubmit">
      <van-field
        ref="merchname"
        v-model="merchname"
        name="merchname"
        label="商户名称"
        placeholder="请输入商户名"
        :rules="[{ required: merchruzhuid > 0 && merchname.length > 0 ? false : true, message: '请输入商户名' }]"
        @blur="checkMerchname"
      />
      <van-field
        v-model="salecate"
        name="salecate"
        label="主营类目"
        placeholder="请输入主营产品类目"
        :rules="[{ required: merchruzhuid > 0 && salecate.length > 0 ? false : true, message: '请输入主营产品类目' }]"
      />
      <van-field
        v-model="realname"
        name="realname"
        label="联系人"
        placeholder="请输入联系人姓名"
        :rules="[{ required: merchruzhuid > 0 && realname.length > 0 ? false : true, message: '请输入联系人姓名' }]"
      />
      
      <van-field
        v-model="umobile"
        name="umobile"
        type="tel"
        label="登陆手机号"
        placeholder="一旦设置无法修改"
        :rules="[{ required: true, message: '请设置您的登录名' }]"
      />
      <van-field
        v-if="showpwd"
        v-model="password"
        type="password"
        name="pwd"
        label="登陆密码"
        placeholder="设置您的密码"
        :rules="[{ required: showpwd, message: '请设置您的密码' }]"
      />
      <van-field
        v-if="showpwd"
        v-model="password1"
        type="password"
        name="pwd1"
        label="密码确认"
        placeholder="请再次输入您的密码"
        :rules="[{ required: showpwd, message: '请再次输入您的密码' }]"
      />
      <div style="margin: 16px;">
        <van-button block type="danger" native-type="submit">
          下一步
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "GonghuoApply2",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      merchname: "",
      salecate: "",
      realname: "",
      umobile: "",
      password: "",
      password1: "",
      shoptype: 0, // 商户类型
      merchuserid: "", // 已开通商户id
      regid: 0,
      shareid: 0,
      merchid: 0,
      show: false,
      reason: "",
      status: 0,
      merchruzhuid: 0,
      showpwd: true
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "填写入驻信息");
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getlianmeng() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/applyshops/get_lianmeng_shop")
        .then(res => {
          console.log(res);
          if(res.data.code == 100000) {
            _that.merchname = res.data.data.merchname
            _that.realname = res.data.data.realname
            _that.salecate = res.data.data.salecate
            _that.merchruzhuid = res.data.data.id
            if (res.data.data.account && res.data.data.account.pwd && res.data.data.account.pwd.length > 0) {
              _that.showpwd = false;
            } else {
              _that.showpwd = true;
            }
            Dialog.alert({
              title: '已有联盟店铺',
              message: '您已开通联盟店铺该页面修改的店铺信息会同步修改您的联盟店铺信息和密码',
              theme: 'round-button',
            })
            .then(() => {
              // on confirm
                
            })
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/get_fenxiao_reg",
          _that.$qs.stringify({
            type: 13
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.info = res.data.data;
            _that.umobile = _that.info.mobile
            _that.merchname = _that.info.merchname
            _that.realname = _that.info.realname
            _that.shoptype = _that.info.shoptype
            _that.salecate = _that.info.salecate
            _that.regid = _that.info.regid
            _that.reason = _that.info.reason
            _that.status = parseInt(_that.info.status)
            if (_that.status == -1) {
              _that.show = true;
            }else{
              _that.show = false;
            }
            if (!_that.regid || parseInt(_that.regid) <= 0) {
              _that.getlianmeng();
            }
            
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    onClickLeft() {
      if (this.umobile) {
        this.umobile = "";
      }
      this.$router.back();
    },
    
    // 商铺名称唯一性判断
    checkMerchname() {
      let _that = null;
      _that = this;
      if (this.merchname) {
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/check_merchname",
          _that.$qs.stringify({
            merchname: _that.merchname,
            regid: _that.regid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code != 100000) {
            _that.$notify({
              type: "warning",
              message: res.data.msg ? res.data.msg : "店铺名已存在"
            })
            _that.$refs.merchname.focus();
          }
        })
        .catch(error => {
          console.log(error);
        })
      }
    },
    // 获取已通过审核的商户id
    getregid(){
      if (this.merchuserid && parseInt(this.merchuserid) > 0) {
        let _that = null;
        _that = this;
        _that.$axios
          .post(
            _that.$store.state.domain + "web/applyshops/get_merchuserid",
            _that.$qs.stringify({
              id: _that.merchuserid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.merchname = res.data.data.merchname;
              _that.salecate = res.data.data.salecate;
              _that.realname = res.data.data.realname;
              _that.umobile = res.data.data.mobile;
              _that.shoptype = res.data.data.shoptype;
              _that.regid = res.data.data.regid;
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    onSubmit() {
      let _that = null;
      _that = this;
      if (_that.password !== _that.password1) {
        _that.$toast({
          message: "两次密码输入不一致",
          duration: 1500
        });
        return false;
      }

      let gonghuo1 = null;
      gonghuo1 = _that.$qs.stringify({
        merchname: _that.merchname,
        salecate: _that.salecate,
        realname: _that.realname,
        umobile: _that.umobile,
        regid: _that.regid,
        shoptype: _that.shoptype,
        merchuserid: _that.merchuserid,
        memberid: localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : '',
        password: _that.password,
        password1: _that.password1,
        merchruzhuid: _that.merchruzhuid
      })
      localStorage.setItem("gonghuo1", gonghuo1);
      _that.$router.push({
        name: "GonghuoApply3",
        query: {
          regid: _that.regid,
          shoptype: _that.shoptype,
          shareid: _that.shareid,
          merchid: _that.merchid,
          t: new Date().getTime()
        }
      });
        
      /*    
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/apply_gonghuo1",
          _that.$qs.stringify({
            merchname: _that.merchname,
            salecate: _that.salecate,
            realname: _that.realname,
            umobile: _that.umobile,
            password: _that.password,
            password1: _that.password1,
            regid: _that.regid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$notify({
              type: "success",
              message: res.data.msg ? res.data.msg : "保存成功"
            })
            _that.$router.push({ name: "GonghuoApply3", query: { regid: res.data.data, shoptype: _that.shoptype, shareid: _that.shareid, merchid: _that.merchid } });
          }else {
            _that.$notify({
              type: "warning",
              message: res.data.msg ? res.data.msg : "申请失败"
            })
          }
        })
        .catch(error => {
          console.log(error);
        })
      */
    }
  }
}
</script>
<style lang="less">
.apply2 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-cell {
    line-height: 30px;
    .van-cell__value {
      flex: 1;
    }
  }
  .seconds {
    color: #ffffff;
    font-size: 12px;
  }
  .van-notice-bar__content{
    text-align: left;
  }
}
</style>